import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Interviews: the breakdown",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "interviews-the-breakdown",
  "draft": false,
  "meta_title": "Interviews: the breakdown"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`The Interview Process`}</strong></p>
    <p>{`The job interview is a culmination of all of your efforts to sell yourself as a viable candidate for the target position you are seeking. For the hiring manager and recruiter, the interview is a chance to meet you face-to-face and to determine whether or not you are a good culture fit for the organization.`}</p>
    <p>{`While the interview process varies from firm to firm it generally follows a general structure involving an initial phone screening, then an in-person behavioral interview, followed by a technical or case interview, and ultimately a final behavioral style interview with the key decision-maker.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.themuse.com/advice/10-types-of-interviews-and-how-to-ace-them"
      }}>{`The Muse`}</a>{` cites that interviews may also take on several different formats: e.g. phone interviews, video interviews, and in-person interviews – which include case interviews, panel interviews, and group interviews. In addition, at certain companies, your interview process may take an entire day – or even several days.`}</p>
    <p>{`Regardless of what style or structure the interviews end up taking on, the best way to establish yourself as the most suitable candidate for the job is to prepare extensively. This includes a focus on developing a detailed knowledge of the position and its responsibilities; conducting granular research on the company and the ecosystem in which it operates; determining the cultural characteristics of the firm and team you will be working on, and identifying the key recruiters and hiring managers who you will be interviewing with.`}</p>
    <p>{`At the core, the interview process is about fit, for both yourself and for the employer – but generally more so for the employer. “An interview is actually about how you can help your future boss and future employer succeed. It’s about finding out what their requirements and hopes are and matching up your background and experience with what they need (The Ladders)." Throughout the process always remember to make it as easy as possible for the hiring manager and the interviewers to say yes.`}</p>
    <p><strong parentName="p">{`Interview Formats`}</strong></p>
    <p>{`With regard to the styles and structures of interviews, it’s best to break them down into formats and types. The formats as mentioned in the previous paragraph are phone interviews, video interviews, and in-person interviews – including one-on-one interviews, panel interviews, and group interviews. “A call is typically a first-round screening to see if you’re a fit to come in for a full interview, so nailing it is key,” according to The Muse. In these formats it’s recommended to dress in your interview attire, standup and project your voice with a measured and confident tone, and have your research notes in front of you in preparation for potential questions that may arise.`}</p>
    <p>{`With Video Interviews it’s essential again to dress appropriately; however, in these settings – e.g. Skype and Google Hangout – it’s important to remember to maintain eye contact with the camera and to understand that your movements and mannerisms may be accentuated and that the microphone may be overly sensitive. Additionally, it’s critical to ensure that your background is amendable to a formal interview setting. With in-person interviews, it’s vital to build rapport, to establish credibility, and to be thoughtful and engaging. It is additionally incredibly important to listen carefully in order to provide appropriate responses, but also to build a relationship with the interviewer. In a one-on-one setting, it’s critical to build a rapport with the interviewer and to give concise answers while summarizing the central point upfront.`}</p>
    <p>{`With Panel Interviews the company will have more than one person interviewing you at the same time. In this type of interview, it’s crucial to speak to each interviewer when answering questions and to remain relevant to each individual on the panel; this can be done by answering the question of the panelist who asked it and then adds other aspects that might be relevant to the other panelists. Group Interviews are used to evaluate candidates’ teamwork and general interaction with other people. The key in this format style is to exhibit creativity, confidence, and leadership to differentiate yourself.`}</p>
    <p><strong parentName="p">{`Interview Types`}</strong></p>
    <p>{`The different types of interviews are generally grouped into four categories: Screening, Behavioral, Technical, and Case.`}</p>
    <p>{`Screening interviews are usually conducted by a recruiter in order to determine your baseline fit with the organization and to reduce the overall volume of candidates. They are typically done over the phone to verify values, interests, and general suitability for the job. In this style of an interview, it’s important to keep your answers short and concise and to thoughtfully articulate why you would be a good fit for the job.`}</p>
    <p>{`“In a Behavioral Interview, you will be asked to describe past situations that exemplified your ability to use the skills, abilities, or knowledge required for the job. Your challenge is to convince the interviewer that you have the initiative, interest, skills and mature competence to do the job" according to Darden Alumni. The optimal way to frame the relevant stories or examples that you provide to the interviewer in this setting this through the `}<a parentName="p" {...{
        "href": "https://www.darden.virginia.edu/alumni/career-services/job-search-toolkit/landing-job/type"
      }}>{`STAR Format`}</a>{`: Situation, Task, Action, Result. The STAR format is easy to use and is the generally accepted way to answer behavioral interview questions. When making use of this technique to answer questions focus on utilizing the Situation and Task to frame your story and put the emphasis on the Action(s) that you took and the Result that was achieved, along with lessons learned associated with it.`}</p>
    <p>{`“Case interview questions are hypothetical problems you are asked to solve as part of the interview. The purpose of the case interview is to gauge how well you listen, your logical problem-solving abilities, how you formulate a plan, and whether you can articulate a solution under pressure. Generally, there is no right answer to the case question, but rather the interviewer is evaluating your approach, structure, analysis, poise, and communications style.”`}</p>
    <p>{`Technical Interviews are a type of interview that evaluates programmers, developers, IT professionals and engineers on their talents and abilities. In this type of format, it’s best to prepare by brushing up on the specific skill set that is required for the role and to bring a portfolio of your work – e.g. apps or projects that you’ve worked on, and be prepared to clearly write out all solutions on a whiteboard per `}<a parentName="p" {...{
        "href": "http://www.cio.com/article/2383000/careers-staffing/how-to-prepare-for--and-ace--the-technical-interview.html"
      }}>{`CIO Magazine`}</a>{`. Although this specific type has been scaled back a bit recently, if you are in a highly quantitative and technical field, it’s imperative that you remain prepared for a technical interview in your area of expertise.`}</p>
    <p>{`As a veteran, you have a great capability to provide meaningful contributions to many different firms. Your `}<a parentName="p" {...{
        "href": "http://www.purepost.co"
      }}>{`Purepost`}</a>{` resume and work ethic combined with your dynamic problem-solving skills make you an indispensable team member.`}</p>
    <p>{`As you approach the interview it’s important to remember to thoughtfully sell yourself to the employer and to articulate how you will be able to add value to their organization. Through diligent research and deliberate practice, you can refine your presentation and train yourself to be prepared to succeed in any interview scenario.`}</p>
    <p>{`~The Purepost Team`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      